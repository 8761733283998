import { useContext } from 'react';
import { Card, Carousel } from 'antd';
import { history } from 'umi';
import { LinkService } from '@/services/LinkService';
import QuudyHomeSideTabs from '@/components/QuudyHomeSideTabs';

import styles from './index.less';
import QuudyHomeCustomSiteIcons from '@/components/QuudyHomeCustomSiteIcons';
import QuudyHomeEventBlock from '@/components/QuudyHomeEventBlock';
import { EventService } from '@/services/EventService';
import { EventKind } from '@/services/datatypes/Kind';
import type { ModelServerData } from '@/services/datatypes/BaseModel';
import QuudyHomeRecommendBlock from '@/components/QuudyHomeRecommendBlock';
import { RecommendService } from '@/services/RecommendService';
import type { AdvModel } from '@/services/datatypes/Adv';
import { AdvService } from '@/services/AdvService';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import HeaderSearch from '@/components/HeaderSearch';
import PromiseBuilder from '@/components/PromiseBuilder';
import CookieDependBuilder from '@/components/CookieDependBuilder';
import { QuudyUserInfoContext } from '@/components/QuudyPageLayout';
import { LinkLogService } from '@/services/LinkLogService';
// import QuudyHomeLinkAffix from '@/components/QuudyHomeLinkAffix';
import CommonPageGenerator from '@/pages/common';

type PageProp = any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Home: React.FC<PageProp> = (props: PageProp) => {
  const quudyUserContext = useContext(QuudyUserInfoContext);
  const quudyUserInfo = quudyUserContext?.quudyUserInfo;
  const quudyNeighborhoodInfo = quudyUserContext?.quudyNeighborhoodInfo;
  // 登录用户情报
  const userInfo = quudyUserInfo?.[0] ?? quudyNeighborhoodInfo?.[0];
  const screens = useBreakpoint(true);
  if (screens.xs === undefined) {
    // 桌面版的useBreakPoint会先执行一遍mobile的breakpoint之后再渲染实际桌面宽度的画面
    // 渲染完xs是必有值的
    // 因此跳过，否则会发生无用的请求
    return <></>;
  }
  let quudyHomeLeftCol;
  let quudyHomeCenterCol;
  let quudyHomeRightCol;

  const linkSideBarBlock = (
    <PromiseBuilder
      key={'linkSideBarBlock'}
      promiseGet={function (): Promise<any> {
        // 链接
        const linkService = new LinkService();
        return linkService.getCategorizedLinksInfo();
      }}
      builder={(data) => {
        return <QuudyHomeSideTabs linksInfo={data} />;
      }}
      loadingAreaStyle={{
        height: 390,
        backgroundColor: '#e5e5e580',
        margin: 2,
      }}
    />
  );

  const headerSeach = (
    <HeaderSearch
      placeholder="Quudyを検索する"
      defaultValue=""
      defaultVisible={true}
      options={[]}
      onSearch={(value) => {
        history.push('/search/' + value);
      }}
    />
  );

  const customIconLinkBlock = (
    <PromiseBuilder
      key={'customIconLinkBlock'}
      promiseGet={function (): Promise<any> {
        // 链接
        const linkService = new LinkService();
        return linkService.getIconLinkInfo();
      }}
      builder={(data) => {
        return (
          <QuudyHomeCustomSiteIcons
            mainIconLinkInfoList={data[0]}
            customIconLinkInfoList={data[1]}
          />
        );
      }}
      loadingAreaStyle={{
        height: 236,
        backgroundColor: '#e5e5e580',
        margin: 2,
      }}
    />
  );

  const recommendLinkBlock = (mobile: boolean) => (
    <CookieDependBuilder
      key={'recommendLinkBlock'}
      builder={(cookie) => {
        const areaKey = cookie('town_id') ?? cookie('city_id') ?? cookie('ken_id');
        return (
          <PromiseBuilder
            key={'recommendLinkBlock-' + areaKey}
            promiseGet={function (): Promise<any> {
              const recommendService = new RecommendService();
              return recommendService.getRecommendCategoryInfo();
            }}
            builder={(data) => {
              return <QuudyHomeRecommendBlock recommendLinkCategoryList={data} mobile={mobile} />;
            }}
            loadingAreaStyle={{
              height: 614,
              backgroundColor: '#e5e5e580',
              margin: 2,
            }}
          />
        );
      }}
      dependencies={['district_id', 'ken_id', 'city_id', 'town_id']}
    />
  );

  const eventBlock = (mobile: boolean) => (
    <CookieDependBuilder
      key={'eventBlock'}
      builder={(cookie) => {
        const userAreaKey =
          userInfo?.townId?.serverValue() ??
          userInfo?.cityId?.serverValue() ??
          userInfo?.kenId?.serverValue();
        const areaKey = cookie('town_id') ?? cookie('city_id') ?? cookie('ken_id');
        return (
          <PromiseBuilder
            key={'eventBlock-' + (userAreaKey ?? areaKey)}
            promiseGet={function (): Promise<any> {
              const eventService = new EventService();
              return eventService.getEventPerKindNumber({}, Object.values(EventKind));
            }}
            builder={(data) => {
              return <QuudyHomeEventBlock initEventInfo={data} mobile={mobile} />;
            }}
            loadingAreaStyle={{
              height: 700,
              backgroundColor: '#e5e5e580',
              margin: 2,
            }}
          />
        );
      }}
      dependencies={['district_id', 'ken_id', 'city_id', 'town_id']}
    />
  );

  const advBlock = (
    <PromiseBuilder
      key={'advBlock'}
      promiseGet={function (): Promise<any> {
        const advService = new AdvService();
        return advService.getAdv();
      }}
      builder={(data) => {
        return (
          <Card bodyStyle={{ padding: 0 }}>
            <Carousel
              dotPosition="top"
              autoplay
              style={{
                height: 200,
              }}
            >
              {(data as ModelServerData<AdvModel>[]).map((adv) => {
                return (
                  <div key={adv.advId}>
                    <div
                      style={{
                        margin: '0 auto',
                      }}
                    >
                      <a
                        href={adv.address ?? undefined}
                        target="_blank"
                        onClick={() => {
                          new LinkLogService().logAdvClick(adv.advId ?? '');
                        }}
                      >
                        <img
                          style={{
                            width: '100%',
                            height: 200,
                          }}
                          src={'/img/info/' + adv.img ?? '/img/ai_1.png'}
                        />
                      </a>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </Card>
        );
      }}
      loadingAreaStyle={{
        height: 200,
        backgroundColor: '#e5e5e580',
        margin: 2,
      }}
    />
  );
  if (!screens.md && !screens.lg && !screens.xl && !screens.xxl) {
    // md以及md以下时,左侧边栏和右侧边栏省略，所有内容渲染到中间一栏中
    quudyHomeCenterCol = (
      <div className={styles.quudyHomeCenterCol}>
        {headerSeach}
        {customIconLinkBlock}
        {/* <div
          style={{
            paddingRight: 10,
            textAlign: 'center',
          }}
        >
          <PromiseBuilder
            key={'linkAffixBlock'}
            promiseGet={function (): Promise<any> {
              // 链接
              const linkService = new LinkService();
              return linkService.getCategorizedLinksInfo();
            }}
            builder={(data) => {
              return <QuudyHomeLinkAffix linksInfo={data} />;
            }}
            loadingAreaStyle={{
              display: 'none',
            }}
          />
        </div> */}
        {recommendLinkBlock(true)}
        {eventBlock(true)}
        {advBlock}
      </div>
    );
  } else if (!screens.lg && !screens.xl && !screens.xxl) {
    // md以上，xl以下时,左侧边栏省略
    quudyHomeCenterCol = (
      <div className={styles.quudyHomeCenterCol}>
        {customIconLinkBlock}
        {eventBlock(false)}
      </div>
    );
    quudyHomeRightCol = (
      <div className={styles.quudyHomeRightCol}>
        {recommendLinkBlock(false)}
        {advBlock}
      </div>
    );
  } else {
    // xl以及以上，三个栏
    quudyHomeLeftCol = <div className={styles.quudyHomeLeftCol}>{linkSideBarBlock}</div>;
    quudyHomeCenterCol = (
      <div className={styles.quudyHomeCenterCol}>
        {customIconLinkBlock}
        {eventBlock(false)}
      </div>
    );
    quudyHomeRightCol = (
      <div className={styles.quudyHomeRightCol}>
        {recommendLinkBlock(false)}
        {advBlock}
      </div>
    );
  }
  const pageContent = (
    <>
      {quudyHomeLeftCol}
      {quudyHomeCenterCol}
      {quudyHomeRightCol}
    </>
  );
  // }
  return pageContent;
};

export default CommonPageGenerator(Home);
